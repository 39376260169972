import React, { useState, useEffect } from 'react';
import useTodoItems from './useTodoItems'; // Adjust the import path as necessary
import EditIcon from '../Icons/EditIcon';
import CloseIcon from '../Icons/CloseIcon';
import CheckIcon from '../Icons/CheckIcon';
import Toast from './Toast';

function TodoList({ userInfo }) {
  const { todoItems, addTodoItem, removeTodoItem, toggleTodoItem, updateTodoItem } = useTodoItems(userInfo.uid);
  const [inputVal, setInputVal] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [editText, setEditText] = useState("");
  const [deletedItem, setDeletedItem] = useState(null);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setEditingId(null);
        setEditText("");
      } else if (e.key === 'Enter' && editingId) {
        handleEditSubmit(editingId);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [editingId, editText]);

  const addItem = (e) => {
    e.preventDefault();
    if (inputVal) {
      addTodoItem(inputVal);
      setInputVal("");
    }
  };

  const editTodoItem = (id, text) => {
    setEditingId(id);
    setEditText(text);
  };

  const handleEditSubmit = async (id) => {
    if (editText.trim()) {
      await updateTodoItem(id, editText);
      setEditingId(null);
      setEditText("");
    }
  };

  const cancelEdit = () => {
    setEditingId(null);
    setEditText("");
  };

  const handleDelete = async (id, text) => {
    const item = { id, text };
    setDeletedItem(item);
    await removeTodoItem(id);
    setShowToast(true);
  };

  const handleUndo = async () => {
    if (deletedItem) {
      await addTodoItem(deletedItem.text);
      setDeletedItem(null);
      setShowToast(false);
    }
  };

  return (
    <>
      <form onSubmit={addItem} id="todoListForm">
        <input
          name="addItemInput"
          onChange={e => setInputVal(e.target.value)}
          value={inputVal}
          placeholder="Add item...  📦  🛠 📱"
          className="addItemInput"
        ></input>
        <button className="mainBtn signOutBtn circleBtn">ADD</button>
      </form>
      {todoItems.map(item => (
        <div className="todoList" key={item.id}>
          <input
            type="checkbox"
            id={item.id}
            checked={item.done}
            onChange={() => toggleTodoItem(item.id, !item.done)}
          />
          {editingId === item.id ? (
            <input
              type="text"
              name="editItemInput"
              value={editText}
              onChange={(e) => setEditText(e.target.value)}
              className="addItemInput"
              style={{
                flex: 1,
                marginRight: '10px',
                height: '67px',
                lineHeight: '30px',
                fontSize: '1.2rem',
                background: '#3c4258',
                border: '1px solid #fff',
                borderRadius: '50px',
                color: '#fff',
                padding: '20px 30px'
              }}
            />
          ) : (
            <label htmlFor={item.id}>{item.text}</label>
          )}
          <div className="todoListButtons">
            {editingId === item.id ? (
              <>
                <button
                  className="editButton"
                  onClick={() => handleEditSubmit(item.id)}>
                  <CheckIcon />
                </button>
                <button
                  className="closeButton"
                  onClick={cancelEdit}>
                  <CloseIcon />
                </button>
              </>
            ) : (
              <>
                <button
                  className="editButton"
                  onClick={() => editTodoItem(item.id, item.text)}>
                  <EditIcon />
                </button>
                <button
                  className="closeButton"
                  onClick={() => handleDelete(item.id, item.text)}
                ><CloseIcon /></button>
              </>
            )}
          </div>
        </div>
      ))}
      {showToast && (
        <Toast
          message="Item deleted"
          onUndo={handleUndo}
          onClose={() => setShowToast(false)}
        />
      )}
    </>
  );
}

export default TodoList;