import React, { useEffect } from 'react';
import './Toast.css';

const Toast = ({ message, onUndo, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="toast">
      <span>{message}</span>
      {onUndo && (
        <button onClick={onUndo} className="undo-button">
          Undo
        </button>
      )}
    </div>
  );
};

export default Toast;